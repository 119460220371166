import {request} from "@/network/request";

export function searchPosts(data) {
    return request({
        params: data,
        method: 'get',
        url: '/post/super/queryPost'
    })
}

export function getRoles(data) {
    return request({
        params: data,
        method: 'get',
        url: '/sysRole/selectRole'
    })
}

export function sendPost(data) {
    return request({
        params: data,
        method: 'post',
        url: '/post/sendPost'
    })
}
export function updatePost(data) {
    return request({
        params: data,
        method: 'post',
        url: '/post/updatePost'
    })
}

export function deletePost(data) {
    return request({
        params: data,
        method: 'post',
        url: '/post/deletePost'
    })
}

export function recoverPost(data) {
    return request({
        params: data,
        method: 'post',
        url: '/post/recoverPost'
    })
}