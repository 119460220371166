<template>
    <div style="padding: 10px;height: 100%;" class="d-flex flex-column">
        <!--    弹窗    -->
        <!--    发布、编辑公告    -->
        <v-dialog v-model="publishDialog" max-width="450" persistent>
            <v-card>
                <v-card-title class="text-h6">
                    {{publishDialogTitle}}公告
                </v-card-title>
                <v-card-text style="padding: 10px 30px">
                    <el-form ref="form" :model="publishData" label-width="80px">
                        <el-form-item label="接收对象">
                            <el-select v-model="publishData.receiver" placeholder="请选择接收对象"
                                       @change="publishData.receiver=$event">
                                <el-option v-for="r in receivers" :label="r.beanName" :value="r.beanId"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="发布时间">
                            <el-select v-model="publishData.custom" placeholder="请选择时间">
                                <el-option label="立即发送" value="now"></el-option>
                                <el-option label="自定义" value="custom"></el-option>
                            </el-select>
                            <div v-show="publishData.custom==='custom'" style="margin: 10px 0">
                                <el-col :span="11">
                                    <el-date-picker type="date" placeholder="选择日期" v-model="publishData.date"
                                                    style="width: 100%;"></el-date-picker>
                                </el-col>
                                <el-col class="line" :span="2" align="center">-</el-col>
                                <el-col :span="11">
                                    <el-time-picker placeholder="选择时间" v-model="publishData.time"
                                                    style="width: 100%;"></el-time-picker>
                                </el-col>
                            </div>
                        </el-form-item>
                        <el-form-item label="公告内容">
                            <el-input type="textarea" v-model="publishData.content" rows="8"></el-input>
                        </el-form-item>
                    </el-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="cancelPublish">
                        取消
                    </v-btn>
                    <v-btn color="green darken-1" text @click="publishDialogButtonClick">
                        发布
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--    查看公告    -->
        <v-dialog v-model="showDialog" max-width="450" persistent>
            <v-card>
                <v-card-title class="text-h6">
                    查看公告详情
                </v-card-title>
                <v-card-text style="padding: 10px 30px">
                    <el-form ref="form" :model="showData" label-width="80px">
                        <el-form-item label="发送者:">
                            <el-tag type="">{{showData.sender}}</el-tag>
                        </el-form-item>
                        <el-form-item label="接收对象:">
                            <el-tag type="">{{showData.receiver}}</el-tag>
                        </el-form-item>
                        <el-form-item label="发布时间:">
                            <el-tag type="">{{showData.time}}</el-tag>
                        </el-form-item>
                        <el-form-item label="公告内容:">
                            <el-input type="textarea" v-model="showData.content" rows="8"></el-input>
                        </el-form-item>
                    </el-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="cancelShow">
                        确定
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--    删除公告    -->
        <v-dialog v-model="deleteDialog" max-width="450" persistent>
            <v-card>
                <v-card-title class="text-h6">
                    {{deleteDialogTitle}}公告
                </v-card-title>
                <v-card-text style="padding: 10px 30px">
                    确定要{{deleteDialogTitle+" id 为 "+tableData.length>0?tableData[tableIndex].postId:-1}} 的公告吗?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="cancelDelete">
                        取消
                    </v-btn>
                    <v-btn color="green darken-1" text @click="deletePost">
                        {{deleteDialogTitle}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--    弹窗    -->
        <v-card elevation="1" class="flex-grow-1" style="padding: 10px">
            <el-input v-model="searchContent" style="width: 200px;" clearable
                      placeholder="请输入内容" prefix-icon="el-icon-search">
            </el-input>
            <v-btn color="primary" @click="search(searchContent)" style="margin-left: 10px">
                <v-icon left>
                    mdi-magnify
                </v-icon>
                搜索
            </v-btn>
            <v-btn color="primary" @click="publishDialogShow" style="float:right">
                <v-icon left>
                    mdi-plus
                </v-icon>
                发布公告
            </v-btn>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column label="id" width="60" align="center" prop="postId"></el-table-column>
                <el-table-column label="发布者" width="110" align="center" prop="userName">
                    <template slot-scope="scope">
                        <el-tag type="">{{scope.row.userName}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="公告内容" align="center" prop="content"></el-table-column>
                <el-table-column label="接收者" width="110" align="center">
                    <template slot-scope="scope">
                        <el-tag type="success">{{scope.row.receiveRoleName}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="发布时间" sortable width="150" align="center"
                                 prop="postTime"></el-table-column>
                <el-table-column label="操作" align="center" width="229">
                    <template slot-scope="scope">
                        <el-button size="mini"
                                   @click="handleShow(scope.$index, scope.row)">详情
                        </el-button>
                        <el-button size="mini"
                                   @click="handleEdit(scope.$index, scope.row)">编辑
                        </el-button>
                        <el-button size="mini" style="color: white"
                                   :type="scope.row.deleteStatus===true?'warning':'danger'"
                                   @click="showDeleteDialog(scope.$index, scope.row)"
                                   v-text="scope.row.deleteStatus===true?'恢复':'删除'">
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                    style="padding: 0;margin-top: 15px;margin-right: 15px"
                    align="right"
                    background
                    layout="prev, pager, next"
                    :total="tableData.length">
            </el-pagination>
        </v-card>
    </div>
</template>

<script>
    import * as post from "../../network/Details/Super_admin/postManage";

    export default {
        name: "PostManage",
        created() {
            this.search("");
            post.getRoles().then(res => {
                if (res.status === 200) {
                    this.receivers = res.data
                }
            })
        },
        data: () => ({
            tableData: [],
            searchContent: '',
            publishDialog: false,
            publishDialogTitle: "",
            showDialog: false,
            showData: {},
            editDialog: false,
            deleteDialog: false,
            deleteDialogTitle: "",
            deleteDialogText: "",
            tableIndex: 0,
            receivers: [],
            publishData: {},
            sendUserId: window.localStorage.getItem("userId"),
        }),
        methods: {
            cancelShow() {
                this.showData = {}
                this.showDialog = false
            },
            cancelPublish() {
                this.publishData = {}
                this.publishDialog = false
            },
            cancelDelete() {
                this.deleteDialog = false
                this.deleteDialogTitle = ""
                this.deleteDialogText = ""
            },
            publishDialogShow() {
                this.publishDialogTitle = "发布"
                this.publishDialog = true;
            },
            publishDialogButtonClick() {
                let data = this.publishData;
                if (data.receiver === undefined || data.content === undefined || data.custom === undefined) {
                    this.$message({
                        message: "填写不完整",
                        type: "error"
                    })
                    return;
                }
                if (data.custom === "custom") {
                    if (data.date === undefined || data.time === undefined) {
                        this.$message({
                            message: "填写不完整",
                            type: "error"
                        })
                        return;
                    }
                }
                let date = new Date(data.date);
                let time = new Date(data.time);
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                let hour = time.getHours();
                let min = time.getMinutes();
                let seconds = time.getSeconds();
                let dateString = year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + seconds;
                let custom = data.custom === "custom";
                if (new Date(dateString) < new Date()) {
                    this.$message({
                        message: "发布时间不能小于当前时间",
                        type: "error"
                    })
                    return;
                }
                let flag = this.publishDialogTitle === "发布"
                if (flag) {
                    post.sendPost({
                        content: data.content,
                        postTime: custom ? dateString : transformTime(new Date()),
                        receiverRoleId: data.receiver,
                        sendUserId: this.sendUserId
                    }).then(res => {
                        if (res.status === 200) {
                            this.search("")
                            this.cancelPublish();
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                } else {
                    let r = data.receiver;
                    for (let i = 0; i < this.receivers.length; i++) {
                        let receiver = this.receivers[i]
                        if (receiver.beanName === r) {
                            r = receiver.beanId;
                            break;
                        }
                    }
                    let d = {
                        postId: this.tableData[this.tableIndex].postId,
                        content: data.content,
                        postTime: custom ? dateString : transformTime(new Date()),
                        receiverRoleId: r,
                        sendUserId: this.sendUserId
                    }
                    post.updatePost(d).then(res => {
                        if (res.status === 200) {
                            this.search("")
                            this.cancelPublish();
                        }
                    }).catch(err => {
                        console.log(err)
                    })
                }
            },
            search(content) {
                post.searchPosts({
                    content: content === undefined ? "" : content
                }).then(res => {
                    if (res.status === 200) {
                        this.tableData = res.data;
                    }
                    console.log(res)
                }).catch(err => {
                    console.log(err);
                })
            },
            handleShow(index, row) {
                this.showDialog = true
                this.showData.content = row.content;
                this.showData.time = row.postTime;
                this.showData.sender = row.userName;
                this.showData.receiver = row.receiveRoleName;
            },
            handleEdit(index, row) {
                this.tableIndex = index
                this.publishDialogTitle = "编辑"
                this.publishDialog = true
                this.publishData = {
                    date: row.postTime,
                    time: row.postTime,
                    custom: "custom",
                    receiver: row.receiveRoleName,
                    content: row.content,
                }
            },
            showDeleteDialog(index, row) {
                let flag = row.deleteStatus;
                this.deleteDialogTitle = flag ? "恢复" : "删除"
                this.deleteDialogText = "确定" + this.deleteDialogTitle + " id 为 " + row.postId + " 的公告吗"
                this.tableIndex = index
                this.deleteDialog = true
            },
            deletePost() {
                let flag = this.deleteDialogTitle === "恢复"
                if (flag) {
                    post.recoverPost({
                        postId: this.tableData[this.tableIndex].postId
                    }).then(res => {
                        if (res.status === 200) {
                            this.$message({
                                message: "恢复成功",
                                type: "success"
                            })
                            this.tableData[this.tableIndex].deleteStatus = false
                            this.deleteDialog = false
                        }
                    })
                } else {
                    post.deletePost({
                        postId: this.tableData[this.tableIndex].postId
                    }).then(res => {
                        if (res.status === 200) {
                            this.$message({
                                message: "删除成功",
                                type: "success"
                            })
                            this.tableData[this.tableIndex].deleteStatus = true
                            this.deleteDialog = false
                        }
                    })
                }
            }
        },
    }

    function transformTime(date) {
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let min = date.getMinutes();
        let seconds = date.getSeconds();
        return year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + seconds
    }
</script>

<style scoped>

</style>